import React from 'react'
import Navbar from '../components/shared/Navbar'
import HistoryContainer from '../components/history/HistoryContainer'

const HistoryPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <HistoryContainer />
    </>
  )
}

export default HistoryPage
