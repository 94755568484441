import React from 'react'
import Login from '../components/auth/Login'

const AuthPage: React.FC = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default AuthPage
