import React from 'react'
import LandingPageNavbar from '../components/landing-page/LandingPageNavbar'
import Pricing from '../components/landing-page/Pricing'

const PricingPage: React.FC = () => {
  return (
    <>
      <LandingPageNavbar />
      <Pricing />
    </>
  )
}

export default PricingPage
