import React from 'react'
import Navbar from '../components/shared/Navbar'
import ViewProfile from '../components/organization-profile/ViewProfile'

const ViewOrganizationProfilePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <ViewProfile />
    </>
  )
}

export default ViewOrganizationProfilePage
