import React from 'react'
import Navbar from '../components/shared/Navbar'
import EditProfile from '../components/organization-profile/EditProfile'

const EditOrganizationProfilePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <EditProfile />
    </>
  )
}

export default EditOrganizationProfilePage
